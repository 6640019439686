import * as React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Image from 'next/image'
import { useEmblaCarousel } from 'embla-carousel/react'
import { ASPECT_RATIOS } from 'utils/constants'
import Section from 'components/Section'
import Typography from 'components/Typography'
import Link from 'components/Link'

const BREAKPOINT_KEY_UP = 'sm'

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -1,
    borderTop: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    paddingBottom: 16,
    overflow: 'hidden',
  },

  main: {
    position: 'static',
    display: 'grid',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  area: {
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      minHeight: 200,
    },
  },

  title: {
    position: 'relative',
    marginTop: '16px',
    marginBottom: '16px',
    textAlign: 'center',
  },

  text: {
    paddingRight: theme.spacing(4),
  },
  emblaSlide: {
    position: 'relative',
    padding: '0px 16px 0px 16px',
    minWidth: 203,
    minHeight: 100,
    overflow: 'hidden',
  },
  mediaArea: {
    display: 'flex',
    position: 'relative',
    maxWidth: '100%',
  },
  embla: {
    position: 'relative',
    padding: '10px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  embla__viewport: {
    overflow: 'hidden',
    width: '100%',
  },
  embla__container: {
    display: 'flex',
    userSelect: 'none',
    marginLeft: '-10px',
  },
}))

function InThePress(props) {
  const { items, title } = props
  const classes = useStyles(props)

  const [emblaRef] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
  })

  return (
    <Section className={classes.root}>
      <div className={classes.title}>
        <Typography className={classes.text} variant="subtitle2">
          {title}
        </Typography>
      </div>

      <div className={classes.embla}>
        <div className={classes.embla__viewport} ref={emblaRef}>
          <div className={classes.embla__container}>
            {items?.map((image, idx) => (
              <div key={idx} className={classes.emblaSlide}>
                {image?.linkUrl ? (
                  <Link href={image?.linkUrl} target="_blank" underline="always" variant="inherit">
                    <Image
                      src={image?.imageUrl.filename}
                      objectfit="contain"
                      priority
                      {...ASPECT_RATIOS.smallImage}
                      alt={`Embla slide link image ${idx}`}
                    />
                  </Link>
                ) : (
                  <Image
                    src={image?.imageUrl.filename}
                    objectfit="contain"
                    priority
                    {...ASPECT_RATIOS.smallImage}
                    alt={`Embla slide image ${idx}`}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Section>
  )
}

const itemType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.object.isRequired,
  linkUrl: PropTypes.string,
})

InThePress.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(itemType).isRequired,
}

export default InThePress
